
// Copied from stack overflow
function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// We need the version from our version number, so it always is in sync.
const params = parseQuery(self.location.search);

// This doesnt work yet
let loadingTimeStamp = new Date().getTime();

// Get all javascript that we need to cache
const scriptBundles = params.scripts.split(',').map(bundle => decodeURIComponent(bundle));

let currentCaches = [`resurs-${params.version}`];

const calculateTimeDelta = (startTime, endTime = new Date().getTime()) => {
    return endTime;
    // return endTime - startTime;
};

async function clearOldCaches (current) {
    let list = await caches.keys();
    list.forEach(key => {
        if (current.indexOf(key) === -1) {
            console.log('Cleaning up cache "%s"', key); // eslint-disable-line
            caches.delete(key).catch(err => console.error('SW Delete Cache Error:', err)); // eslint-disable-line
        }
    });
}

self.addEventListener('activate', function(event) {
    event.waitUntil(clearOldCaches(currentCaches));
    console.log(`SW event "activate" ${calculateTimeDelta(loadingTimeStamp)} ms!`); // eslint-disable-line
});

self.addEventListener('activated', function() {
    console.log(`SW event "activated" ${calculateTimeDelta(loadingTimeStamp)} ms!`); // eslint-disable-line
});

self.addEventListener('fetch', function(event) {

    // 1. First wer attempt to get stuff from the cache
    // 2. If we dont find it there, we check if its a request from
    //      our own server. 
    //      3.a.1. If it is, and we try to fetch our offline version
    //      3.a.2. If it wails, well, we try to do a fetch again, but we know it will fail
    //    If its not from our own server
    //      3.b.1. We try to fetch it from their server

    event.respondWith(

        caches.match(event.request).then(function(response) {

            if (response) {
                return response;
            } 

            return fetch(event.request)
                .catch(err => { // eslint-disable-line

                    // If we are offline, any request not handled by the cache
                    // that is within our domain, should render an offline response
                    if (event.request.url.indexOf(self.location.origin) > -1) { // && errorString.indexOf('Failed to fetch') > -1) {

                        let cacheRequest = new Request('/offline');
                        return caches.match(cacheRequest).then(function(response) {
                            return response || fetch(event.request);
                        });
                    }

                });

        })

    );

});

let assetsToCache = [

    // '/offline', // Not used yet

    // Style
    '/css/style.css',

    // Fonts
    '/assets/fonts/resurs-sans/ResursSans-Bold.woff2',
    '/assets/fonts/resurs-sans/ResursSans-Display.woff2',
    '/assets/fonts/resurs-sans/ResursSans-Regular.woff2',
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap',

    // Assets
    '/assets/logos/resurs-logo-blue.svg',

    // External js, production bundles, dev bundles can be handled by CDN all the time
    `https://unpkg.com/react@${params.reactVersion}/umd/react.production.min.js`,
    `https://unpkg.com/react-dom@${params.reactVersion}/umd/react-dom.production.min.js`,

];

assetsToCache = assetsToCache.concat(scriptBundles);

self.addEventListener('install', async function(e) {

    // Activate the new service worker without the requirement to reload everything. 
    // This is only recomended if you dont have multuple sites depending on old code at 
    // the same time (https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#skip_the_waiting_phase)
    self.skipWaiting();

    e.waitUntil(
        caches.open(currentCaches[0]).then(function(cache) {

            console.log('Caching new version %s', currentCaches[0]); // eslint-disable-line

            return cache.addAll(assetsToCache);
        })
        .catch(err => console.error(err)) // eslint-disable-line
    );

    console.log(`SW event "install" ${calculateTimeDelta(loadingTimeStamp)} ms!`); // eslint-disable-line

});


console.log(`SW Ready after ${calculateTimeDelta(loadingTimeStamp)} ms!`); // eslint-disable-line


